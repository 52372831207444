<mat-toolbar [color]="toolbarColor" [class.is-public]="isPublic">
  <div class="logo-wrapper">
    <a *ngIf="isPublic" class="logo-link" [routerLink]="'/'">
      <img class="logo" [src]="logoPublicSrc" [width]="logoPublicWidth" alt="Marketplace Logo" />
    </a>
    <ng-content select="[slot-start]"></ng-content>
  </div>
  <div>
    <ng-content select="[slot-center]"></ng-content>
  </div>
  <div>
    <ng-content select="[slot-end]"></ng-content>
  </div>
</mat-toolbar>
