import { Component, ChangeDetectionStrategy, TemplateRef, Attribute, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortalService } from '@common/services/portal.service';

@Component({
  selector: 'mar-portal',
  templateUrl: './portal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalComponent {
  public readonly template$: Observable<TemplateRef<any> | null>;

  @Input() context: any;

  constructor(@Attribute('name') name: string, portalService: PortalService) {
    this.template$ = portalService.state$.pipe(map((instance) => instance && instance[name]));
  }
}
