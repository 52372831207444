import { Component, ChangeDetectionStrategy } from '@angular/core';

import { LoaderService } from '@common/services/loader.service';

@Component({
  selector: 'mar-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
