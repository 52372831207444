import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mar-question-dialog',
  templateUrl: './question-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { content: string; title: string }
  ) {}
}
