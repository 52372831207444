import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '@common/services/notification.service';

@Injectable({ providedIn: 'root' })
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((e) => {
        this.notificationService.notify(e?.error?.message || 'Error');

        console.log(JSON.stringify(e, null, 2));
        return throwError(e);
      })
    );
  }
}
