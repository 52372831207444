import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subscription, timer } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { NotificationService } from './notification.service';
import { ProfilesService } from '@api/services/profiles.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class SessionTimerService implements OnDestroy {
  private readonly tokenAliveTime = 30 * 60 * 1000; // 30 minutes
  private readonly notifyUserTime = 5 * 60 * 1000; // 5 minutes
  private readonly lastRequestTime$ = new BehaviorSubject<number>(this.localStorageValue);
  private notificationRef?: MatSnackBarRef<TextOnlySnackBar>;
  private logoutSub?: Subscription;
  private notificationSub?: Subscription;
  private setLocalStorageSub?: Subscription;
  private sharedLastRequestTime$: Observable<number> = of(0);
  private userActionList$?: Observable<Event>;
  private userActivityTimer: any;
  private userActionListSubscription$?: Subscription;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private sessionService: SessionService,
    private profilesService: ProfilesService
  ) {
    const storageChangeEvent$ = fromEvent(window, 'storage').pipe(
      map(() => this.localStorageValue),
      filter((storageTime) => !!storageTime && +storageTime > this.lastRequestTime$.getValue()),
      map((storageTime) => +storageTime!)
    );

    const delayTime = 500;

    this.sharedLastRequestTime$ = merge(this.lastRequestTime$, storageChangeEvent$).pipe(
      distinctUntilChanged(),
      debounceTime(delayTime),
      map((t) => (t ? t - delayTime : 0)),
      filter((t) => t > 0)
    );

    this.updateLocalStorageSub();
    this.updateLogoutSub();
    this.updateNotificationSub();
    this.startWatchingUserActivity();
  }

  private updateLocalStorageSub(): void {
    this.setLocalStorageSub?.unsubscribe();

    this.setLocalStorageSub = this.sharedLastRequestTime$.subscribe((lastRequestTime) => {
      this.hideNotification();
      localStorage.setItem('lastRequestTime', lastRequestTime.toString());
    });
  }

  private updateLogoutSub(): void {
    this.logoutSub?.unsubscribe();

    this.logoutSub = this.sharedLastRequestTime$
      .pipe(
        map((lastRequestTime) => this.tokenAliveTime - (Date.now() - lastRequestTime)),
        switchMap((logoutTime) => timer(logoutTime))
      )
      .subscribe(async () => {
        await this.logout();
      });
  }

  private updateNotificationSub(): void {
    this.notificationSub?.unsubscribe();

    this.notificationSub = this.sharedLastRequestTime$
      .pipe(
        map(
          (lastRequestTime) =>
            this.tokenAliveTime - (Date.now() - lastRequestTime) - this.notifyUserTime
        ),
        switchMap((showNotificationTime) => timer(showNotificationTime))
      )
      .subscribe(() => {
        this.notifyAboutLogout();
      });
  }

  private get localStorageValue(): number {
    return +(localStorage.getItem('lastRequestTime') || '0');
  }

  public updateLastRequestTime(): void {
    this.sessionService.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe(() => {
        this.lastRequestTime$.next(Date.now());
        this.updateLogoutSub();
        this.updateNotificationSub();
        this.hideNotification();
      });
  }

  public stopTimers(): void {
    localStorage.removeItem('lastRequestTime');
    this.lastRequestTime$.next(0);
    this.logoutSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
    this.hideNotification();
  }

  private notifyAboutLogout(): void {
    const dueDate = Date.now() + this.notifyUserTime;

    this.notificationRef = this.notificationService.notify(
      `You will be logged out at ${new Date(
        dueDate
      ).toLocaleTimeString()}. Click Refresh to stay logged in!`,
      'Refresh',
      { duration: undefined }
    );

    this.notificationRef
      ?.afterDismissed()
      .pipe(
        filter(({ dismissedByAction }) => dismissedByAction),
        withLatestFrom(this.sessionService.user$)
      )
      .pipe(concatMap(([_, user]) => this.profilesService.getProfileInfo(user?.id!)))
      .subscribe(() => {
        this.notificationService.notify('Your session has been refreshed!');
      });
  }

  public async logout(): Promise<void> {
    await this.router.navigateByUrl('/');
    this.sessionService.logout();
    this.stopTimers();
    this.notificationRef = this.notificationService.notify(
      'You have been logged out of MarketPlace. Please login to continue.',
      'Got it',
      { duration: undefined }
    );
  }

  private hideNotification(): void {
    this.notificationRef?.dismiss();
  }

  private startWatchingUserActivity() {
    this.userActionList$ = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'keypress')
    );
    this.userActionListSubscription$ = this.userActionList$.subscribe(() => {
      if (this.localStorageValue) {
        this.clearTimer();
        this.userActivityTimer = setTimeout(() => {
          this.updateLastRequestTime();
        }, 2000);
      }
    });
  }

  private clearTimer() {
    if (this.userActivityTimer) {
      clearTimeout(this.userActivityTimer);
      this.userActivityTimer = null;
    }
  }

  public ngOnDestroy(): void {
    this.logoutSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
    this.setLocalStorageSub?.unsubscribe();
    this.lastRequestTime$.complete();
    this.userActionListSubscription$?.unsubscribe();
    this.clearTimer();
    
  }
}
