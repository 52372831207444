import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermGuard } from '@common/guards/term.guard';
import { AuthRedirectGuard } from '@common/guards/auth-redirect.guard';
import { ChooseRoleGuard } from '@common/guards/choose-role.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'connections',
    loadChildren: () =>
      import('./views/connections/connections.module').then((m) => m.ConnectionsModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'deleted-assets',
    loadChildren: () =>
      import('./views/deleted-assets/deleted-assets.module').then((m) => m.DeletedAssetsModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'provider',
    loadChildren: () => import('./views/provider/provider.module').then((m) => m.ProviderModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./views/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'institutions',
    loadChildren: () =>
      import('./views/institutions/institutions.module').then((m) => m.InstitutionsModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'assets',
    loadChildren: () => import('./views/assets/assets.module').then((m) => m.AssetsModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'seeker-provider',
    loadChildren: () =>
      import('./views/seeker-provider/seeker-provider.module').then((m) => m.SeekerProviderModule),
    canActivate: [AuthRedirectGuard, TermGuard, ChooseRoleGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./views/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'seeker',
    loadChildren: () => import('./views/seeker/seeker.module').then((m) => m.SeekerModule),
    canActivate: [AuthRedirectGuard, TermGuard],
  },
  {
    path: 'request-access',
    loadChildren: () =>
      import('./views/request-access/request-access.module').then((m) => m.RequestAccessModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./views/terms/terms.module').then((m) => m.TermsModule),
    canActivate: [AuthRedirectGuard],
  },
  {
    path: 'user-support',
    loadChildren: () =>
      import('./views/user-support/user-support.module').then((m) => m.UserSupportModule),
  },
  {
    path: '**',
    loadChildren: () => import('./views/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
