import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { GetProfileInfoResponseDTO, ProfileInfoDTO } from '@api/models/profiles-dto';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  private readonly apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) {}

  public getProfileInfo(id: string): Observable<ProfileInfoDTO> {
    return this.http
      .get<GetProfileInfoResponseDTO>(`${this.apiURL}/api/profiles/${id}`)
      .pipe(map(({ profiles }) => profiles[0]));
  }

  public updateProfileInfo(
    id: string,
    profile: Partial<ProfileInfoDTO>
  ): Observable<ProfileInfoDTO> {
    return this.http
      .put<GetProfileInfoResponseDTO>(`${this.apiURL}/api/profiles/${id}`, { profile })
      .pipe(map(({ profiles }) => profiles[0]));
  }
}
