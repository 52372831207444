import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToolbarModule } from '@common/components/toolbar/toolbar.module';
import { UserMenuModule } from '@common/components/user-menu/user-menu.module';
import { PortalModule } from '@common/components/portal/portal.module';

import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, ToolbarModule, UserMenuModule, PortalModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
