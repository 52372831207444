import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { concatMapTo, debounce, distinctUntilChanged, finalize, tap } from 'rxjs/operators';

import { LoaderService } from '@common/services/loader.service';

@Injectable({ providedIn: 'root' })
export class LoaderInterceptor implements HttpInterceptor, OnDestroy {
  private runningRequests = 0;
  private loaderStateChanges = new Subject<boolean>();
  private subs: Subscription[] = [];

  constructor(private loader: LoaderService) {
    const sub = this.loaderStateChanges
      .pipe(
        debounce((val) => (val ? of(undefined) : interval(100))),
        distinctUntilChanged()
      )
      .subscribe((visible) => {
        if (visible) {
          this.loader.show();
        } else {
          this.loader.hide();
        }
      });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }

    this.loaderStateChanges.complete();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(null).pipe(
      tap(() => {
        this.runningRequests++;
        if (this.runningRequests === 1) {
          this.loaderStateChanges.next(true);
        }
      }),
      concatMapTo(next.handle(request)),
      finalize(() => {
        this.runningRequests--;
        if (this.runningRequests === 0) {
          this.loaderStateChanges.next(false);
        }
      })
    );
  }
}
