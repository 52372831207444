import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { SessionService } from '@common/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class TermGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.sessionService.user$.pipe(
      first(),
      filter((user) => !!user),
      map((user) => {
        if (route.routeConfig?.path !== 'terms' && user?.forceAgreement) {
          return this.router.createUrlTree(['terms', { userId: user?.id }]);
        }

        return !user?.forceAgreement;
      })
    );
  }
}
