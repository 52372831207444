import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionService } from './session.service';
import { UserInfo } from '@common/models/login';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  private readonly routes: { [key in UserInfo['role']]: string } = {
    admin: '/admin/dashboard',
    provider: '/provider',
    seeker: '/seeker',
    'seeker-provider': '/seeker-provider',
  };
  public readonly homeRoute$: Observable<string> = this.sessionService.role$.pipe(
    map((role) => (role ? this.routes[role] : '/'))
  );
  public readonly isPublic$ = this.sessionService.role$.pipe(
    map((role) => !(role && this.routes[role]))
  );

  constructor(private sessionService: SessionService) {}
}
