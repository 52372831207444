import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'mar-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @Input() public isPublic?: boolean | null = true;

  public logoPublicSrc = '/assets/img/marketplace-logo.png';
  public logoPublicWidth = '600';

  constructor() {}

  public get toolbarColor(): ThemePalette {
    return this.isPublic ? undefined : 'accent';
  }
}
