import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { LinksService } from '@common/services/links.service';
import { SessionService } from '@common/services/session.service';
import { SessionTimerService } from '@common/services/session-timer.service';
import { UserInfo } from '@common/models/login';
import { RolesService } from '@common/services/roles.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from '@common/components/question-dialog/question-dialog.component';

@Component({
  selector: 'mar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  public readonly user$: Observable<UserInfo | undefined> = this.sessionService.user$.pipe(
    map((user) => user ?? user)
  );
  public readonly userName$: Observable<string> = this.sessionService.user$.pipe(
    map((user) => (user ? `${user.firstName} ${user.lastName}` : ''))
  );

  constructor(
    private router: Router,
    public linksService: LinksService,
    public sessionService: SessionService,
    private sessionTimerService: SessionTimerService,
    public rolesService: RolesService,
    private dialog: MatDialog
  ) {}

  public logout(): void {
    this.dialog
      .open(QuestionDialogComponent, {
        data: { title: 'Logout', content: 'Are you sure you wish to log out?' },
      })
      .afterClosed()
      .pipe(
        filter((answer) => answer === 'yes'),
        take(1)
      )
      .subscribe(async () => {
        await this.router.navigateByUrl('/');
        this.sessionTimerService.stopTimers();
        this.sessionService.logout();
      });
  }
}
