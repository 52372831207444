import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { RoleChangeDTO } from '@api/models/role-dto';
import { LoginResponseDTO } from '@api/models/login-dto';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private readonly apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) {}

  public changeRole(role: string): Observable<RoleChangeDTO> {
    return this.http.post<LoginResponseDTO>(`${this.apiURL}/api/role`, { role });
  }
}
