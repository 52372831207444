import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SessionService } from '@common/services/session.service';
import { SessionTimerService } from '@common/services/session-timer.service';

@Injectable({ providedIn: 'root' })
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private sessionTimerService: SessionTimerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const session = this.sessionService.getSession();

    const req = session?.token
      ? next.handle(
          request.clone({
            headers: request.headers.set('Authorization', `Token: ${session.token}`),
          })
        )
      : next.handle(request);

    return req.pipe(
      tap(() => {
        this.sessionTimerService.updateLastRequestTime();
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.router.navigateByUrl('/');
          this.sessionService.logout();
        }

        return throwError(err);
      })
    );
  }
}
