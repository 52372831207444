import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  public readonly loaderSubj$ = new Subject<boolean>();

  public show(): void {
    this.loaderSubj$.next(true);
  }

  public hide(): void {
    this.loaderSubj$.next(false);
  }
}
