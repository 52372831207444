import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '@common/services/session.service';
import { LinksService } from '@common/services/links.service';

@Injectable({
  providedIn: 'root',
})
export class ChooseRoleGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private linksService: LinksService
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.sessionService.user$, this.linksService.homeRoute$]).pipe(
      map(([user, homeRoute]) => {
        if (user && (user?.role === 'seeker-provider' || user?.isSeekerProvider)) {
          return true;
        }

        return this.router.createUrlTree([homeRoute]);
      })
    );
  }
}
