import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoginService } from '@api/services/login.service';
import { RoleService } from '@api/services/role.service';
import { SessionInfo, UserInfo } from '@common/models/login';

const sessionKey = 'session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private session?: SessionInfo = JSON.parse(localStorage.getItem(sessionKey) || 'null');

  public readonly role$ = new BehaviorSubject<UserInfo['role'] | undefined>(
    this.session?.user?.role
  );
  public readonly user$ = new BehaviorSubject<UserInfo | undefined>(this.session?.user);

  constructor(private authService: LoginService, private roleService: RoleService) {}

  public getSession(): SessionInfo | undefined | null {
    return this.session;
  }

  public login(email: string, password: string): Observable<SessionInfo> {
    return this.authService.login(email, password).pipe(
      tap((data) => {
        localStorage.setItem(sessionKey, JSON.stringify(data));
        this.session = data;
        this.role$.next(data.user.role);
        this.user$.next(data.user);
      })
    );
  }

  public changeRole(role: UserInfo['role']): Observable<unknown> {
    return this.roleService.changeRole(role).pipe(
      tap((data) => {
        if (this.session) {
          if (this.session.user.role === 'seeker-provider') {
            this.session.user.isSeekerProvider = true;
          }

          this.session.token = data.token;
          this.session.user.role = role;
          localStorage.setItem(sessionKey, JSON.stringify(this.session));
          this.changeUserInfo(this.session.user);
          this.role$.next(role);
        }
      })
    );
  }

  public changeUserInfo(user: UserInfo): void {
    const updatedUser = { ...this.session?.user, ...user };
    localStorage.setItem(sessionKey, JSON.stringify({ ...this.session, user: updatedUser }));
    this.session!.user = updatedUser;
    this.user$.next(updatedUser);
  }

  logout(): void {
    localStorage.removeItem(sessionKey);
    this.session = undefined;
    this.role$.next(undefined);
    this.user$.next(undefined);
  }
}
