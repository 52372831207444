import { Component, ChangeDetectionStrategy } from '@angular/core';

import { LinksService } from '@common/services/links.service';
import { RolesService } from '@common/services/roles.service';

@Component({
  selector: 'mar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(public linksService: LinksService, public rolesService: RolesService) {}
}
