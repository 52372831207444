import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { SessionService } from '@common/services/session.service';
import { LinksService } from '@common/services/links.service';
import { combineLatest } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const icons = [
  'home',
  'user',
  'assets',
  'connect-mini',
  'star-bordered',
  'chat-question',
  'logout',
  'bank',
  'delete',
  'people',
  'list-italic',
  'plus',
  'arrow-left',
  'edit',
  'eye-opened',
  'eye-closed',
  'transfer',
  'print',
  'search',
  'download',
  'save',
  'connection-sent',
  'close',
  'lock-refresh',
  'arrow-on',
  'refresh',
  'info',
  'star-filled',
  'view-blank',
  'ring',
  'rss',
  'compare',
];

@Component({
  selector: 'mar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private linksService: LinksService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
      );
    });
  }

  public ngOnInit(): void {
    const currentUrl$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(({ url }) => url)
    );

    combineLatest([this.sessionService.user$, this.linksService.homeRoute$, currentUrl$])
      .pipe(
        filter(([user]) => !!user),
        take(1)
      )
      .subscribe(async ([, homeRoute, currentUrl]) => {
        if (currentUrl === '/') {
          await this.router.navigateByUrl(homeRoute);
        }
      });
  }
}
