import { Injectable } from '@angular/core';

import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public notify(
    message: string,
    action: string = 'Close',
    config: MatSnackBarConfig = { duration: 5000 }
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, config);
  }
}
