import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { SessionInterceptor } from '@common/interceptors/session.interceptor';
import { LoaderInterceptor } from '@common/interceptors/loader.interceptor';
import { ErrorsInterceptor } from '@common/interceptors/errors.interceptor';
import { LoaderModule } from '@common/components/loader/loader.module';
import { HeaderModule } from '@common/components/header/header.module';
import { PortalModule } from '@common/components/portal/portal.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionDialogModule } from '@common/components/question-dialog/question-dialog.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    LoaderModule,
    HeaderModule,
    PortalModule,
    MatDialogModule,
    QuestionDialogModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}