import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionService } from '@common/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public isAdmin$: Observable<boolean> = this.sessionService.role$.pipe(
    map((role) => role === 'admin')
  );
  public isSeeker$: Observable<boolean> = this.sessionService.role$.pipe(
    map((role) => role === 'seeker')
  );
  public isProvider$: Observable<boolean> = this.sessionService.role$.pipe(
    map((role) => role === 'provider')
  );
  public isSeekerProvider$: Observable<boolean> = this.sessionService.role$.pipe(
    map((role) => role === 'seeker-provider')
  );
  public isProviderOrAdmin$: Observable<boolean> = combineLatest([
    this.isProvider$,
    this.isAdmin$,
  ]).pipe(map(([isProvider, isAdmin]) => isProvider || isAdmin));
  public isProviderOrSeeker$: Observable<boolean> = combineLatest([
    this.isProvider$,
    this.isSeeker$,
  ]).pipe(map(([isProvider, isSeeker]) => isProvider || isSeeker));
  public isAdminOrSeeker$: Observable<boolean> = combineLatest([
    this.isAdmin$,
    this.isSeeker$,
  ]).pipe(map(([isAdmin, isSeeker]) => isAdmin || isSeeker));

  constructor(private sessionService: SessionService) {}
}
