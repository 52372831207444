import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PortalPayload {
  [target: string]: TemplateRef<any>;
}

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  public readonly state$ = new BehaviorSubject<PortalPayload | null>(null);

  constructor() {}

  private get currentState(): PortalPayload {
    return this.state$.value || {};
  }

  public add(payload: PortalPayload): void {
    this.state$.next({ ...this.currentState, ...payload });
  }

  public remove(target: string): void {
    delete this.currentState[target];

    this.state$.next(this.currentState);
  }
}
