<mar-toolbar [isPublic]="linksService.isPublic$ | async">
  <ng-container slot-start>
    <ng-container *ngIf="!(linksService.isPublic$ | async)">
      {{ (rolesService.isAdmin$ | async) ? 'MarketPlace Administrator' : 'MarketPlace' }}
    </ng-container>
    &#x2060;
    <mar-portal class="header-title" name="header-title"></mar-portal>
  </ng-container>
  <mar-portal slot-center name="header"></mar-portal>
  <mar-portal slot-end name="header-end"></mar-portal>
  <mar-user-menu slot-end></mar-user-menu>
</mar-toolbar>
