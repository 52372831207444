import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';

import { PortalService } from '@common/services/portal.service';

@Directive({
  selector: '[marPortal]',
})
export class PortalDirective implements OnChanges, OnDestroy {
  @Input() marPortal: string = '';

  constructor(private portalService: PortalService, private template: TemplateRef<any>) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.marPortal) {
      return;
    }

    const { marPortal } = changes;

    marPortal.previousValue && this.portalService.remove(marPortal.previousValue);

    marPortal.currentValue && this.portalService.add({ [marPortal.currentValue]: this.template });
  }

  public ngOnDestroy() {
    this.marPortal && this.portalService.remove(this.marPortal);
  }
}
